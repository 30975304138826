/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersColumns } from "../Data/UsersListData";
import CustomTable from "../../../Core/Components/CustomTable";
import UserIcon from "../../../Common/Svgs/UserIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { getDriversV3 } from "../../../Redux/APIs/usersAPI";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";

const LOCAL_DEFAULT_LIMIT = 10;

const DriversList = ({ driversCurrentPage, setDriversCurrentPage }) => {
  const dispatch = useDispatch();

  const { driversListV3 } = useSelector((state) => state.users);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  // fetch drivers
  useEffect(() => {
    dispatch(
      getDriversV3({
        offset: ((driversCurrentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      }),
    );
  }, [dispatch, driversCurrentPage]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <div>
      {/* className="max-w-[calc(100vw-10px)]" */}
      <CustomTable
        // scroll="calc(100vh - 211.8px)"
        scrollX={0}
        columns={usersColumns}
        data={driversListV3.result}
        emptyState={
          <UserIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
        }
        rowClassName="group cursor-pointer"
        pagination={{
          pageSize: LOCAL_DEFAULT_LIMIT,
          total: driversListV3.totalRecords,
          showSizeChanger: false,
          onChange: (page) => {
            setDriversCurrentPage(page);
          },
          itemRender: (current, type, originalElement) => {
            const lastPage = driversListV3.totalRecords / current;

            if (type === "prev" && current === 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "prev" && current > 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next" && lastPage <= 10) {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{ pointerEvents: "none" }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next") {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            return originalElement;
          },
        }}
      />
    </div>
  );
};

export default DriversList;
